import React from 'react';

type TeamOverviewProps = {
  teamId: number;
};

export default function TeamOverview({ teamId }: TeamOverviewProps) {  
  let widgetId : string = '';
  switch(teamId) {
    case 1:
      widgetId = '2m68PzqMz5YOl3bjJqVAC5VqSTh';
      break;
    case 2:
      widgetId = '2m68xinYWQSmzzhrX9YIKFxiHwk';
      break;
  }

  return (
    <div>
      <div id={`fp-widget_root-${widgetId}`}>
        <a href="https://www.fupa.net/club/fc-portugiesen-freiburg" target="_blank" rel="noopener">
          FC Portugiesen Freiburg auf FuPa
        </a>
      </div>
      <script src="https://widget-api.fupa.net/vendor/widget.js?v1"></script>
    </div>
  );
}
